<template>
  <InnerScroll :title="content.title" :loading="loading">
    <div v-html="content.body" />
  </InnerScroll>
</template>

<script>
import api from 'api'
import InnerScroll from '../InnerScroll/InnerScroll.vue'

export default {
  components: {
    InnerScroll
  },

  data: () => ({
    content: {},
    loading: false
  }),

  watch: {
    $route: function() {
      this.getContent()
    }
  },
  created() {
    this.getContent()
  },
  methods: {
    getContent: async function() {
      this.loading = true
      this.content = {}

      try {
        const response = await api.call(
          'getContent',
          this.$route.params.contentName
        )
        this.content = {
          title: this.$t(response.data.title),
          body: response.data.body
        }
      } catch (error) {
        this.$error(error)
      } finally {
        this.loading = false
      }
    }
  }
}
</script>
